import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const SupplierSignUpForm = () => {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [pharmName, setPharmName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [message, setMessage] = useState("");

  //Error validation states
  // eslint-disable-next-line
  const [nameError, setNameError] = useState("");
  // eslint-disable-next-line
  const [pharmNameError, setPharmNameError] = useState("");
  // eslint-disable-next-line
  const [phoneError, setPhoneError] = useState("");
  // eslint-disable-next-line
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setNameError("");
    setPharmNameError("");
    setPhoneError("");
    setEmailError("");
    setPasswordError("");

    let hasErrors = false;

    if (name.length < 1 && !name) {
      setNameError("Please enter your name");
      hasErrors = true;
    }

    if (pharmName.length < 1 && !pharmName) {
      setPharmNameError("Please enter your pharmacy name");
      hasErrors = true;
    }

    if (phone.length < 1 && !phone) {
      setPhoneError("Please enter your phone number");
      hasErrors = true;
    }

    if (email.length < 1 && !email) {
      setEmailError("Please enter your email address");
      hasErrors = true;
    }

    if (!email.includes("@") && !email.includes(".")) {
      setEmailError("Please enter a valid email address");
      hasErrors = true;
    }

    if (password.length < 1 && !password) {
      setPasswordError("Please enter your password");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const response = await fetch("https://api.10mg.ai/10mg/register", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          pharmName: pharmName,
          phone: phone,
          email: email,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // eslint-disable-next-line
      const responseData = await response.json();

      if (response.status === 200) {
        setName("");
        setPharmName("");
        setPhone("");
        setEmail("");
        setPassword("");
        setMessage("Registration successful");
        navigate("/confirm-pharmacy");
      } else {
        setMessage("Some error occured");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        method="post"
        target="_top"
        className="flex flex-col gap-6 mt-4 font-ibmPlexSans 
            border border-[#EEEEF0] p-5 rounded-lg"
      >
        <div className="flex flex-col gap-4">
          <label htmlFor="fullName" className="text-sm  text-berkeleyBlue">
            Name of Contact Person
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Your name"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="pharmName"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Company Name
          </label>
          <input
            type="text"
            name="pharmName"
            id="pharmName"
            placeholder="My Logistics"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="fullName"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Phone Number
          </label>
          <input
            type="text"
            name="num"
            id="num"
            placeholder="+234 012 345 6789"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="fullName"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Email Address
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            placeholder="you@company.com"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="password"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Password
          </label>
          <div
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0] 
              flex justify-between items-center"
          >
            <input
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Create new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-transparent w-full outline-none border-0"
            />
            {isPasswordVisible ? (
              <MdOutlineVisibilityOff
                onClick={togglePasswordVisibility}
                className="cursor-pointer text-[#999]"
              />
            ) : (
              <MdOutlineVisibility
                onClick={togglePasswordVisibility}
                className="cursor-pointer text-[#999]"
              />
            )}
          </div>
          <span className="text-red-500">{passwordError}</span>
        </div>
        <div className="flex justify-start items-center gap-2">
          <input type="checkbox" name="agreement" id="agreement" />
          <label
            htmlFor="message"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            I agree to the terms and conditions
          </label>
        </div>
        <button
          type="submit"
          className="inline-flex justify-center rounded-full border border-berkeleyBlue 
                        bg-transparent px-4 py-2 text-sm font-medium text-berkeleyBlue  hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          Register my Business
        </button>
      </form>
      <p className="text-[#808080] font-ibmPlexSans text-center mt-4">
        Already have an account?{" "}
        <Link to="https://app.10mg.ai/signin" className="text-berkeleyBlue">
          Login
        </Link>
      </p>
    </>
  );
};

export default SupplierSignUpForm;
