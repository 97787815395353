import { useRef, useState } from "react";
import "../App.css";

const Terms = () => {
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const [selectedItem, setSelectedItem] = useState("section1");

  const scrollToSection = (elementRef, data) => {
    console.log(data);
    setSelectedItem(data);
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className="w-[100%] p-3 mx-auto  sm:p-2">
      <div className="my-20 sm:flex w-[95%] m-auto">
        <div className="hidden sm:block w-[560px] mt-[132px]">
          <p className="text-sm text-berkeleyBlue font-ibmPlexSans mb-8 font-medium">
            ON THIS PAGE
          </p>
          <ul className="text-berkeleyBlue grid gap-2 text-[14px] font-ibmPlexSans ">
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section1"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section1, "section1")}
            >
              Overview
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section2"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section2, "section2")}
            >
              Accessing our services
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section3"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section3, "section3")}
            >
              Nature of content appearing on our services
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section4"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section4, "section4")}
            >
              Your interactions and communications with us and our services
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section5"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section5, "section5")}
            >
              Miscellaneous
            </li>
          </ul>
        </div>
        <div className="sm:w-[65%]">
          <div className=" flex flex-col gap-8">
            <div className="w-[342px] flex flex-col gap-4 sm:flex-row justify-between">
              <span className="w-max bg-[#DCE9D8] text-xs rounded-full text-center py-1 px-4 text-darkGreen">
                TERMS
              </span>
              <p class="text-sm font-medium text-darkGreen">
                LAST UPDATED - October 16, 22
              </p>
            </div>
            <h1 className="text-xl font-medium font-pp-pangaia text-berkeleyBlue mb-10">
              Terms of Use
            </h1>
          </div>
          <section ref={section1}>
            <h2 className="text-lg mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              Overview
            </h2>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              These terms of use (these "Terms") apply to visitors of 10mg
              Pharma's ("we" "us" or "our") website 10mg.ai, as well as
              registered users of our 10mg Pharma® branded information and
              technology services and mobile applications (our "Services").
              Please review these Terms carefully. Please also review our
              Privacy Policy, which explains how our Services treat your
              personal data and protect your privacy when you use our Services.
              By accessing our Services, you are agreeing to these Terms and our
              Privacy Policy.
            </p>
          </section>
          <section ref={section2}>
            <h2 className="text-xl my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1. Accessing our services
            </h2>
            <h3 className="text-base mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.1 PUBLIC SERVICES
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              If you operate a free, public search engine that has made a public
              commitment to adhering to the robots.txt protocol, together with
              our Sitemaps and Crawl-delay directive (collectively, the
              "Protocol"), you may crawl, index and publish hyperlinks
              (including so-called "deep links") to the Public Services, so long
              as (i) you do so in compliance with the Protocol instructions
              published in applicable locations on our Services, (ii) you do not
              directly or indirectly receive remuneration in connection with the
              provision or display of said hyperlinks and cached pages, and
              (iii) if your user-agent is disallowed by us in our Protocol
              instructions or we otherwise notify you in writing, you shall
              cease all crawling of our Services. We call such a free, public
              search engine satisfying the requirements of clauses (i) through
              (iii), a "Public Search Engine," and the Public Search Engine's
              combined performance of clauses (i) through (iii), collectively,
              "Public Search Services." We may revoke the foregoing
              authorizations at any time as indicated in our Protocol
              instructions. We reserve all rights not expressly granted to you.
              This means that if you wish to use the Public Services in a way
              that is not authorized above, you must receive our permission
              prior to such use.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.2 PROTECTED SERVICES
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Some of our Services are protected by technical measures intended
              to safeguard the confidentiality, integrity and accessibility of
              sensitive information our users store and share using our
              Services. We call these "Protected Services." One such safeguard
              is requiring each user to be properly authenticated by various
              means (which we call, "Credentials"), such as unique identifiers,
              API keys, passwords, and the like. In order to obtain Credentials,
              you must provide certain information about yourself. If you are
              registering for a Protected Service, or accessing or using, or
              attempting to access or use, a Protected Service on behalf of, or
              for the benefit of, someone other than yourself - such as your
              employer, client or customer (each, a "Principal") - you must also
              identify and provide information about each Principal. If you sign
              up for one of our Protected Services you may be asked to agree to
              a user agreement (your "User Agreement"). In such cases, you will
              be asked to expressly consent to your User Agreement, for example,
              by checking a box or clicking on a button with terms such as "I
              agree" or "Create my EHR" or the like. In the event of a conflict
              between the terms of your User Agreement and of these Terms, the
              terms of your User Agreement shall control. Your Credentials are
              unique to you. You should immediately notify us here if your
              Credentials have been stolen or compromised. You are responsible
              for all activities that occur under your Credentials until you
              have properly notified us that your Credentials have been stolen
              or compromised. F­urther, you represent, warrant and covenant
              that:
              <ul className=" list-disc ml-6 mt-6">
                <li>
                  the information you submit in obtaining your Credentials is
                  complete and accurate and identifies you and the name of each
                  of your Principals;
                </li>
                <li>you will not share your Credentials with anyone else;</li>
                <li>
                  you will not circumvent, or attempt to circumvent, any
                  technical measures that we have put in place to safeguard the
                  Protected Services;
                </li>
                <li>
                  you will not, without our prior written approval, access or
                  use, or attempt to access or use, any portion of the Protected
                  Services other than with (i) a commercial browser (such as
                  Chrome, Internet Explorer or Mozilla F­irefox), (ii) an
                  application made for mobile or handheld device(s) that is
                  developed and distributed by us, or (iii) our application
                  programming interface ("API") using Credentials issued to you
                  directly by us, and only us; and
                </li>
                <li>
                  You will not access or use, or attempt to access or use, a
                  Protected Service without validly-issued active Credentials.
                </li>
              </ul>
              We reserve the right to suspend or terminate your access to any
              Protected Service at any time, with or without cause or notice. We
              shall not be liable to you in any way if we suspend or terminate
              your access to a Protected Service or our Services.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.3 Additional Safeguards
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              To further protect the confidentiality, integrity and availability
              of the information housed and shared on our Services, as well as
              the stability of our Services, you agree to the following
              additional safeguards. Accordingly, you agree that you will not,
              nor will you attempt to:
              <ul className=" list-disc ml-6 mt-6">
                <li>
                  access, use or disseminate our Services, nor any information
                  or files accessible via our Services, in a manner that
                  violates any applicable law or regulation or the rights of any
                  individual or entity;
                </li>
                <li>
                  sell or transfer any information included in our Services or
                  use such information to market any product or service -
                  including by sending, or facilitating the sending of,
                  unsolicited emails or SPAM;
                </li>
                <li>
                  probe, scan or test the vulnerability of our Services, or of
                  the system or network supporting our Services, or circumvent
                  any security or authentication measures;
                </li>
                <li>
                  disable, bypass, defeat, avoid, remove, deactivate or
                  otherwise circumvent any technical measures we have
                  implemented to safeguard the stability of our Services, or the
                  confidentiality, integrity or availability of any information,
                  content or data hosted or housed on our Services;
                </li>
                <li>
                  introduce to our Services any software, code or other device
                  that in any way (i) permits unauthorized access to our systems
                  or any software, hardware, files or data located thereon, (ii)
                  disables or damages or otherwise interferes with or adversely
                  affects the operation of our systems or any software,
                  hardware, files or data located thereon, or (iii) overburdens
                  or interferes with the proper functioning of our Services;
                </li>
                <li>
                  disassemble, decompile or reverse engineer our Services;
                </li>
                <li>
                  harvest, retrieve, index or publish any portion of our
                  Services unless you are a Public Search Engine engaging in
                  Public Search Services;
                </li>
                <li>
                  disable or circumvent our API usage safeguards, including
                  safeguards designed to regulate the nature or amount of data
                  you are permitted to extract from our Services, or the
                  frequency of which you may access such data; or make calls to
                  our API other than those authorized in our API documentation;
                </li>
                <li>
                  remove any copyright, trademark or other proprietary rights
                  notices contained in or on our Services; or
                </li>
                <li>
                  engage in any activity other than those expressly permitted in
                  these Terms and your User Agreement(s).
                </li>
              </ul>
            </p>

            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.4 Use of the Services by and on behalf of Minors
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              You are not eligible to use our Services (including obtaining a
              Credential or entering into a User Agreement) unless you are at
              least 18 years old and otherwise have the legal capacity to enter
              into a binding contract in your jurisdiction. If you are an
              unemancipated minor over the age of 13, you may only use our
              Services if« (i) such use has been approved of by your parent or
              legal guardian, (ii) you use our Services under his or her
              supervision, and (iii) he or she has agreed to these Terms (and,
              if applicable, your User Agreement) on your behalf.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              If you are the parent or guardian of an unemancipated minor, you
              may use the Services and enter into a User Agreement on behalf of
              such minor. By doing so, you represent and warrant that you have
              the legal capacity to act on behalf of such minor; and you
              acknowledge and agree, in such capacity, that all provisions of
              these Terms (and User Agreement, if applicable) that apply to you
              are equally applicable to such minor.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Under no circumstances may our Services be used by a child under
              13 years old.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.5 Responsibility
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Access to our Services is intended for users in AF­RICA. You may
              not use our Services in any jurisdiction where offering, accessing
              or using our Services would be illegal or unlawful.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              By using 10mg Pharma services, you accept that 10mg Pharma doesn't
              have any responsibility in using 10mg Pharma services and cannot
              be help liable for any default in services
            </p>
          </section>
          <section ref={section3}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              2. NATURE OF THE CONTENT APPEARING ON OUR SERVICES
            </h2>
            <h3 className="text-base mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.1 Overview
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our Services may include text, data, graphics, images, video or
              other content (collectively, "Content") created by us or third
              parties, including other users, professionals, partners,
              advertisers, sponsors, consumers and governmental agencies. The
              Content is provided for general informational purposes, but should
              not be relied upon for personal, professional, medical or
              regulatory decisions. And we do not ensure the completeness,
              timeliness or accuracy of the Content.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.2 Blog Posts; Ratings; Surveys; Comments; User Submitted Content
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our Services allow you to access blogs, message boards, chat
              services, surveys and other forums where various users can share
              information, opinions, chats and other Content. We generally do
              not pre-screen or monitor user-submitted Content, and such Content
              may simply represent a user's opinion or Content a user finds
              interesting. Our Services may also include survey results, ratings
              or testimonials ("Evaluations") from patients, clients or other
              customers ("Patients") of healthcare professionals
              ("Professionals") that may endorse, recommend, critique, analyze,
              evaluate or otherwise describe the Professionals and the nature or
              quality of the services received by such patient, client or
              customer. Such Evaluations are anecdotal first-hand accounts of
              individual Patients and are neither professional judgments nor the
              product of medical science. Such Evaluations do not in any way
              constitute or imply our endorsement or recommendation of any
              Professional. F­urther, Evaluations are subject to errors and
              biases that are common in anecdotal first-hand accounts, and
              should not be presumed to be reliable or error-free.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.3 Directories: Pharmacists, Physicians, and Other Professionals
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our Services include listings and directories ("Directories") to
              help you find medication suppliers, healthcare professionals,
              hedge funder providers (collectively, "Professionals"). The
              Directories are provided for your convenience. The Directories are
              not comprehensive, but rather generally represent Professionals
              who use our Services and who have chosen to participate in the
              Directories. ­Further, because your interest is our priority, we
              verify the license and specialty certifications of our
              directories. The Professionals are solely responsible for the
              appropriateness and quality of the services they provide.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our Services could use product reviews as a marketing assets.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.4 Advertising
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our Services may include advertisements or promotional messages
              sponsored by third parties (collectively, "Ads"). The
              manufacturers, services providers, distributors and resellers of
              the products and services identified in the Ads are solely
              responsible for the accuracy of their Ads and the suitability,
              efficacy, safety and quality of such products or services. An Ad
              does not in any way constitute or imply our recommendation or
              endorsement of such product or service.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.5 Links to Other Sites
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our Services may contain hyperlinks (including hyperlinked Ads) to
              websites operated by third parties, or to materials or information
              made available by third parties. Such links do not constitute or
              imply our endorsement of such third parties, or of the content of
              their sites, or the suitability, efficacy, safety or quality of
              their products or services, or the information privacy or security
              practices of their websites.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.6 Our Services
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our Content includes medication-related information which are
              provided for general transactional purposes; including, but not
              limited to our blog. We render e-commerce, via our Services or
              otherwise.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.7 No Legal or Regulatory Advice
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Some Content may include regulatory related information pertaining
              to you or your business. Such Content is provided for
              informational purposes only. We are not providing legal or
              regulatory advice and no attorney/ client relationship is created
              by your use of our Services or the Content. Accordingly, always
              seek the advice of your attorney or advisors with any questions
              you may have regarding a law, regulation, or dispute.
            </p>
          </section>
          <section ref={section4}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3. YOUR INTERACTIONS AND COMMUNICATIONS WITH US AND OUR SERVICES
            </h2>
            <h3 className="text-base mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.1 Visiting Our Websites; Signing Up for Our Services; Emailing
              Us
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              When you sign-up for or log-in to one of our Protected Services,
              or when you submit information on a web form or email or instant
              message us, you are communicating with us electronically. When you
              visit our websites, we and certain third-party service providers
              collect information during your visit. In each case, the nature of
              the information we collect, the duration and manner of how we
              retain it, how we use it, and to whom we disclose it, is governed
              by our Privacy Policy.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              As part of providing our Services to you, we may need to provide
              you with certain communications, such as service announcements and
              administrative messages. Such communication may be by email,
              instant message, a posting on our Services or some other
              mechanism. You consent to receive such communications from us
              electronically. If you have registered for one or more of the
              Protected Services, our Service announcements and administrative
              messages we provide you are considered part of the Protected
              Services themselves, which you may not be able to opt-out from
              receiving until you cease using, or deactivate your Credentials
              to, such Protected Services.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              You agree that all agreements, notices, authorizations,
              disclosures and other communications that we provide to you
              electronically, as well as any acceptances, agreements, consents
              or authorizations that you provide to us electronically, satisfy
              any and all legal requirement(s) that such communications be in
              writing.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.2 Your Participation in Our Public Forums
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We may offer one or more forums for the exchange of information
              among our users. You acknowledge that any text, data, graphics,
              images, video or other content ("Content") that you submit in any
              of our forums (including discussion groups, blogs, surveys,
              ratings, comment forms, or message boards, collectively, "Public
              ­Forums") is available to the public. Notwithstanding the
              foregoing, we are under no obligation to display any of your
              Content that you submit, and we reserve the right to remove or
              edit your Content at any time, for any or no reason.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              It is important that you act responsibly when submitting Content
              to a Public F­orum. You acknowledge that any Content that you
              submit in a Public F­orum is available to the public. You are
              solely responsible for any Content that you post on the Public
              ­Forums or transmit to other users of our Services. You
              acknowledge that any information you post in a Public F­orum may
              be available to the public, and may result in your receiving
              communications from others outside our Services.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Your participation in our Public F­orums is subject to our Terms
              of Use and is contingent on your acknowledgement and agreement
              with the following:
              <ul className=" list-disc ml-6 mt-6">
                <li>
                  You will only disclose information about yourself on a Public
                  F­orum that you consider suitable for public disclosure. Do
                  not disclose information that personally identifies you unless
                  you intend for that information to be disclosed publicly. We
                  strongly recommend that you refrain from disclosing any
                  sensitive information about yourself on a Public F­orum,
                  including information about any medical condition.
                </li>
                <li>
                  You will not violate the privacy rights of others, including
                  disclosing information about anyone else's medical or
                  financial condition or any other sensitive subjects.
                </li>
                <li>
                  You will ensure that any Content that you submit to Public
                  ­orums is accurate. If you are rating or reviewing a
                  Professional, you agree to provide your honest appraisals of
                  such Professional, without using inappropriate language or
                  making gratuitous personal criticisms.
                </li>
                <li>
                  You will not post any Content that you do not have the right
                  to post; you will not violate any person's or entity's
                  intellectual property or proprietary rights, including
                  copyrights, trademarks or trade secret rights.
                </li>
                <li>
                  We will not be liable to you for any Content you submit to any
                  Public F­orum.
                </li>
              </ul>
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.3 Removal of Content
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              You understand and agree that we may, but are not obligated to,
              monitor, edit or remove any Content for any or no reason at any
              time. We are not responsible, however, for any delay or failure in
              removing any Content.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.4 Reporting Violations
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We may provide you with tools with which to report violations of
              the Community Standards or other provisions of these Terms.
              Notwithstanding the availability of such tools, you acknowledge
              and agree that we are under no obligation to take any action with
              respect to any such report.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.5 Copyright Policy
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We will respond to notices of alleged copyright infringement that
              comply with applicable law and are properly provided to us. If you
              believe that your Content has been copied in a way that
              constitutes copyright infringement, please provide us with the
              following information:
              <ul className=" list-disc ml-6 mt-6">
                <li>
                  A physical or electronic signature of the copyright owner or a
                  person authorized to act on behalf of the owner, if someone
                  other than you, of an exclusive right that is allegedly
                  infringed;
                </li>
                <li>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit us to locate
                  the material;
                </li>
                <li>
                  A statement by you that you have a good faith belief that use
                  of the material in the manner complained of is not authorized
                  by the copyright owner, its agent, or the law;
                </li>
                <li>
                  A statement that the information in the notification is
                  accurate, and, under penalty of perjury, that you are
                  authorized to act on behalf of the copyright owner; and
                </li>
                <li>
                  Your contact information, including your address, telephone
                  number, and email address
                </li>
                <li>
                  We reserve the right to remove Content alleged to be
                  infringing without prior notice, at our sole discretion, and
                  without liability to you. In appropriate circumstances, 10mg
                  Pharma will also terminate a user's account if the user is
                  determined to be a repeat infringer
                </li>
                <li>
                  Our designated copyright agent for notice of alleged copyright
                  infringement is: 10mg Pharma.
                </li>
              </ul>
            </p>
          </section>
          <section ref={section5}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4. MISCELLANEOUS
            </h2>
            <h3 className="text-base mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.1 Ownership
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              You don't retain ownership of the intellectual property rights in
              Content you submit on our Services. When you submit Content on our
              Services, you grant us and those we work with a worldwide,
              royalty-free right to store, host, reproduce, create derivative
              works of (such as translations, adaptations, reformatted versions
              and aggregated, and anonymized or de-identified versions),
              publish, publicly perform, display, use and distribute such
              Content as further described in our Privacy Policy and, if
              applicable, in your User Agreement. F­or some of our Services,
              your User Agreement or settings may narrow the scope of our use of
              Content you submit. You can find more information about how we use
              and store Content in our Privacy Policy or, if applicable, your
              User Agreement.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              If you submit to us any ideas, suggestions or proposals
              (collectively, "Suggestions") relating to our Services or other
              products or services by any means - such as through "Contact Us,"
              by email or other communication channels, one of our communities
              or user forums, or to our customer support or other personnel -
              such Suggestions (1) are not governed by our Privacy Policy, (2)
              we are under no obligation of confidentiality, express or implied,
              with respect to such Suggestions; (3) we are entitled to use or
              disclose (or choose not to use or disclose) such Suggestions in
              any way and for any purpose; (4) such Suggestions automatically
              become our property without any obligation; and (5) you are not
              entitled to any accounting, compensation or reimbursement of any
              kind from us under any circumstances.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              No Exception for your Content, as between you and us, all right,
              title and interest in and to our Services, the Content, and the
              structure, organization and arrangement thereof, are and remain
              the exclusive property of us and our licensors. Except for the
              limited specific rights we grant you above, you may not reproduce,
              modify or prepare derivative works based upon, distribute, sell,
              transfer, publicly display, publicly perform, exploit or otherwise
              use our Services or any Content.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.2 Violations
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We reserve the right to monitor any and all use of our Services,
              and investigate any activity we suspect violates these Terms, a
              User Agreement, our rights or interest, or the rights or interests
              of any person or entity.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We reserve the right, to the fullest extent permitted under law,
              to cooperate with any governmental authority or third party
              investigating conduct that may be illegal or harm any individual
              or entity or violates their respective rights. If, for example, a
              user threatens to physically harm another user or any other
              individual, we reserve the right to fully cooperate with law
              enforcement authorities and the threatened individual. You hereby
              consent to our cooperation in such investigation.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.3 Indemnification
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              You will indemnify, defend and hold harmless 10mg Pharma and any
              of its affiliates, officers, directors, employees, agents,
              representatives, partners and licensors from and against any and
              all claims, actions, proceedings, suits, liabilities, damages,
              settlements, penalties, fines, losses, or expenses, including
              attorneys' fees, costs and disbursements, arising out of or in any
              way connected with your use of our Services.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.4 Disputes; Governing Law; Jurisdiction
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              The interpretation of these Terms and the resolution of any
              disputes arising under these Terms shall be governed by the laws
              of Lagos State, without regard to its conflict of laws provisions.
              These Terms shall not be governed by the United Nations Convention
              on Contract for the International Sale of Goods, the application
              of which is expressly disclaimed. If any action or other
              proceeding is brought on or in connection with these Terms, you
              agree to submit to the personal jurisdiction of the state and
              federal courts located in Ikeja, Lagos state, and agree not to
              bring any of action relating to the use of our Services or to
              these of these Terms in any court in any jurisdiction other than
              the state or federal courts located in Ikeja, Lagos state. We
              shall have the right to commence and prosecute any legal or
              equitable action or proceeding before any NIGERIAN or nonNIGERIAN
              court of competent jurisdiction to enforce these Terms or to
              protect our or any third party's rights in our Services or any
              data, information or other content made available via our
              Services. You hereby waive any right to a jury trial. You also
              agree that we may bring suit in court to enjoin any violation of
              these Terms without the posting of a bond or security, in addition
              to whatever remedies we might have at law. In any dispute between
              you and us where we prevail, we shall be entitled to recover our
              reasonable attorney fees, court costs, disbursements, and other
              legal expenses.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.5 Termination
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              You agree and acknowledge that we may suspend or terminate your
              authorization to access any of our Services, with or without
              notice or cause, for any or no reason, and without any liability
              to you. Sections 4.1 through 4.14 shall survive any termination or
              expiration of these Terms.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.6 Disclaimers and Limitations on Liability
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              ACCESS TO OUR SERVICES AND THE IN­FORMATION AND CONTENT CONTAINED
              THEREON IS PROVIDED "AS IS" AND "AS AVAILABLE" AND WE HEREBY
              EXPRESSLY DISCLAIM ALL WARRANTIES OF­ ANY KIND, WHETHER EXPRESSED,
              IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO, THE
              IMPLIED WARRANTIES OF­ MERCHANTABILITY, F­ITNESS F­OR A PARTICULAR
              PURPOSE, NONINF­RINGEMENT AND TITLE. WE DO NOT WARRANT THAT THE
              SERVICES WILL BE UNINTERRUPTED, ERROR F­REE OR WITHOUT BREACHES O­
              SECURITY AND YOU AGREE THAT YOU ARE SOLELY RESPONSIBLE F­OR ANY
              AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON OUR
              SERVICES OR THE INF­ORMATION IN OUR SERVICES, INCLUDING INACCURATE
              OR INCOMPLETE IN­FORMATION. WE EXPRESSLY DISCLAIM ANY LIABILITY
              WITH RESPECT TO ANY INJURY CAUSED BY ANY USER, OR ANY DAMAGE
              SUF­­ERED BY ANY USER, AS A RESULT O­F THE ACTIONS OR INACTIONS
              OF­ ANY OTHER USER. IYOU ARE DISSATISF­IED WITH OUR SERVICES OR
              ANY CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
              USING AND ACCESSING OUR SERVICES. SOME JURISDICTIONS DO NOT ALLOW
              THE EXCLUSION OR LIMITATION OF­ IMPLIED WARRANTIES, SO IN THESE
              JURISDICTIONS THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO
              THE USER.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.7 Risks You Assume
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              WITHOUT LIMITING ANY OF­ THE OTHER RISKS WE HAVE DISCLOSED TO YOU
              IN THESE TERMS, YOU ARE SOLELY RESPONSIBLE F­OR YOUR USE OF­ OUR
              SERVICES, INCLUDING ANY CONTENT YOU SUBMIT TO USE AND YOU
              EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE AND ACCESS TO OUR
              SERVICES AND THE INF­ORMATION AND CONTENT CONTAINED THEREIN, AND
              ANY SITES LINKED THROUGH OUR SERVICES AND ANY DATA TRANSMITTED
              THROUGH OUR SERVICES IS AT YOUR SOLE RISK. ACCORDINGLY, WE DO NOT
              ASSUME ANY LIABILITY TO YOU ­FOR OR RELATING TO ANY OF­ YOUR
              ACTIONS, INCLUDING THE PUBLICATION OF­ ANY CONTENT YOU SUBMIT OR
              OUR EXERCISE OF­ THE RIGHTS YOU GRANT TO US WITH RESPECT THERETO.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.8 Limitation of Liability
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              IN NO EVENT SHALL WE OR ANY OF­ OUR SUBSIDIARIES OR AF­­ILIATES,
              OR OUR RESPECTIVE OFF­­ICERS, DIRECTORS, EMPLOYEES, AGENTS,
              REPRESENTATIVES, PARTNERS AND LICENSORS (COLLECTIVELY, THE "10mg
              Pharma ENTITIES") BE LIABLE F­OR ANY SPECIAL, INDIRECT,
              CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED
              TO, LOSS OF­ PROF­ITS OR REVENUES, LOSS OF­ USE, LOSS OGOODWILL OR
              LOSS OF­ INF­ORMATION, HOWEVER CAUSED AND WHETHER BASED ON
              CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER
              THEORY OF­ LIABILITY, EVEN IF­ WE HAVE BEEN APPRISED OF­ THE
              POSSIBILITY OR LIKELIHOOD OF­ SUCH DAMAGES. NOTWITHSTANDING
              ANYTHING IN THESE TERMS TO THE CONTRARY, OUR AGGREGATE LIABILITY
              IN RESPECT OF­ ANY CLAIM OR ACTION YOU MAY BRING AGAINST US OR ANY
              OF­ THE 10mg Pharma ENTITIES, REGARDLESS OF­ F­ORM OF­ ACTION OR
              THEORY OF­ LIABILITY, SHALL BE LIMITED TO THE GREATER O­F (1)
              THIRTY THOUSAND NIGERIAN NAIRA (NAIRA \N30,000) AND (2) THE
              AGGREGATE F­EES ACTUALLY PAID BY YOU TO US F­OR THE SIX (6) MONTH
              PERIOD PRECEDING THE EVENT F­IRST GIVING RISE TO SUCH CLAIM OR
              ACTION. YOU ACKNOWLEDGE THAT YOU MAY BE WAIVING RIGHTS WITH
              RESPECT TO CLAIMS THAT ARE UNKNOWN OR UNSUSPECTED. ACCORDINGLY,
              YOU AGREE TO WAIVE THE BENEF­IT OF­ ANY LAW, INCLUDING, TO THE
              EXTENT APPLICABLE.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.9 Severability
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              If any provision of these Terms is deemed invalid or
              unenforceable, then (a) that provision shall be construed to the
              extent necessary to make it valid and enforceable in such a manner
              as comes closest to preserving the intentions of such provision,
              and (b) the remaining provisions shall remain in full force and
              effect.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.10 No Waiver
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our failure at any time to require performance by you of any
              provision of these Terms shall in no way affect our right to
              enforce such provision, nor shall the waiver of any breach by you
              of any provision herein constitute a waiver of any succeeding
              breach or the provision itself.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.11 Interpretation
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              F­or the purpose of these Terms, "you" means the individual user
              of our Services. If your access or use of our Services is,
              directly or indirectly, on behalf of one or more third parties
              (such as, without limitation, your employer or client, or your
              employer's client, if your employer has been engaged to access our
              Services (any such employer, client or other third parties, a
              "Principal")), then "you" also refers to such Principal. If you
              are using our Services on behalf of a Principal, (a) you represent
              and warrant that you have the authority to bind, and have bound,
              such Principal to these Terms; and (b) you agree to be jointly and
              severally liable for any breach of these Terms by Principal.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              " 10mg Pharma," "we," "our," and "us" means, collectively,
              10ILESGREEN Pharma, Ltd. and our current and future subsidiaries
              and affiliates.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              In addition, the words "include," "includes" and "including" shall
              be deemed to be followed by the phrase "without limitation." The
              word "will" shall be construed to have the same meaning and effect
              as the word "shall." The word "or" shall be construed to have the
              same meaning and effect as "and/or." The words "herein," "hereof"
              and "hereunder," and words of similar import, shall be construed
              to refer to these Terms. The headings used in these Terms are used
              for convenience only and are not to be considered in construing or
              interpreting these Terms.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              These Terms, together with your User Agreement(s), constitute the
              entire agreement between you and 10mg Pharma regarding any
              services accessed via our Services, and supersede all previous
              communications, representations, or understandings, either oral or
              written, relating to the subject matter hereof; provided, however,
              (a) in the event of an express conflict between any specific
              provision included in these Terms and an express provision in a
              User Agreement, the provision set forth in User Agreement shall
              prevail, and (b) these Terms shall cover all rights, obligations,
              terms and conditions not expressly addressed in such User
              Agreement.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.12 Electronic Contracting
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Your use of our Services includes the ability to enter into
              agreements and/or to make transactions electronically. YOU
              ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE YOUR
              AGREEMENT AND INTENT TO BE BOUND BY SUCH AGREEMENTS AND
              TRANSACTIONS. YOUR AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC
              SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL TRANSACTIONS
              YOU ENTER INTO ON THE SERVICES, INCLUDING NOTICES OF­
              CANCELLATION, POLICIES, CONTRACTS, AND APPLICATIONS.
            </p>
            <h3 className="text-sm my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.13 Assignment
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We may freely assign these Terms in connection with a merger,
              acquisition, or sale of assets, or by operation of law or
              otherwise.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.14 Amendments
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We may update or change our Services or the provisions set forth
              in these Terms from time to time and recommend that you review
              these Terms on a regular basis. You understand and agree that your
              continued use of our Services after these Terms have been updated
              or changed constitutes your acceptance of the revised Terms.
              Without limiting the foregoing, if we make a change to these Terms
              that materially affect your use of the Services, we may post a
              notice or notify you via email or our website(s) of any such
              change. The most current version of the Terms shall govern and
              supersede all previous versions.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Terms;
