import { useRef, useState } from "react";

const PrivacyFr = () => {
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const section9 = useRef(null);
  const section10 = useRef(null);
  const [selectedItem, setSelectedItem] = useState("section1");

  const scrollToSection = (elementRef, data) => {
    console.log(data);
    setSelectedItem(data);
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className="w-[100%] p-3 mx-auto sm:p-2">
      <div className="my-20 sm:flex w-[95%] m-auto">
        <div className="hidden sm:block w-[560px] mt-[132px]">
          <ul className="text-berkeleyBlue grid gap-2 text-[14px] font-ibmPlexSans ">
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section1"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section1, "section1")}
            >
              aperçu
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section2"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section2, "section2")}
            >
              Informations d'identification personnelle
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section3"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section3, "section3")}
            >
              Informations d'identification non personnelles
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section4"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section4, "section4")}
            >
              Cookies du navigateur Web
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section5"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section5, "section5")}
            >
              Comment nous protégeons vos informations
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section6"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section6, "section6")}
            >
              Partager vos informations personnelles
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section7"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section7, "section7")}
            >
              Sites Web tiers
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section8"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section8, "section8")}
            >
              Modifications de cette politique de confidentialité
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section9"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section9, "section9")}
            >
              Votre acceptation de ces conditions
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section10"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section10, "section10")}
            >
              nous contacter
            </li>
          </ul>
        </div>
        <div className="sm:w-[60%]">
          <div class=" flex flex-col gap-8">
            <div class="w-[342px] flex flex-col gap-4 sm:flex-row justify-between">
              <span class="w-max bg-[#DCE9D8] text-xs rounded-full text-center py-1 px-4 text-darkGreen">
                Confidentialité
              </span>
              <p class="text-sm font-medium text-darkGreen">
                DERNIÈRE MISE À JOUR - 16 et 22 octobre
              </p>
            </div>
            <h1 class="text-xl font-medium font-pp-pangaia text-berkeleyBlue mb-10">
              politique de confidentialité
            </h1>
          </div>

          <section ref={section1}>
            <h2 className="text-lg mb-6 font-ibmPlexSans text-berkeleyBlue">
              Aperçu
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Cette politique de confidentialité régit la manière dont 10mg
              Pharma recueille, utilise, conserve et divulgue les informations
              recueillies auprès de les utilisateurs (chacun, un « Utilisateur
              ») du site Web 10mg.ai (« Site »). Ce la politique de
              confidentialité s'applique au site et à tous les produits et
              services proposé par 10MILESGREEN PHARMA LTD.
            </p>
          </section>
          <section ref={section2}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Informations d'identification personnelle
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous pouvons collecter des informations d'identification
              personnelle auprès des utilisateurs de manière de diverses
              manières, y compris, mais sans s'y limiter, lorsque les
              utilisateurs visitent notre site, abonnez-vous à la newsletter,
              remplissez un formulaire, et en connexion avec d’autres activités,
              services, fonctionnalités ou ressources que nous mettons à
              disposition sur notre Site. Il peut être demandé aux utilisateurs,
              comme approprié, nom, adresse e-mail, adresse postale, numéro de
              téléphone. Les utilisateurs peuvent toutefois visiter notre Site
              de manière anonyme. Nous collecterons informations
              d'identification personnelle des utilisateurs uniquement s'ils
              nous soumettre volontairement ces informations. Les utilisateurs
              peuvent toujours refuser fournir des informations d'identification
              personnelle, sauf qu'elles peut les empêcher de s’engager dans
              certaines activités liées au Site.
            </p>
          </section>
          <section ref={section3}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Informations d'identification non personnelles
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous pouvons collecter des informations d'identification non
              personnelles sur les utilisateurs chaque fois qu'ils interagissent
              avec notre site. Identification non personnelle les informations
              peuvent inclure le nom du navigateur, le type d'ordinateur et des
              informations techniques sur les moyens de connexion des
              Utilisateurs à notre Site, tels que le système d'exploitation et
              les fournisseurs de services Internet utilisés et autres
              informations similaires.
            </p>
          </section>
          <section ref={section4}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Cookies du navigateur Web
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Notre site peut utiliser des « cookies » pour améliorer
              l'expérience utilisateur. Site Web de l'utilisateur le navigateur
              place des cookies sur leur disque dur pour la tenue de registres
              fins et parfois pour suivre les informations les concernant.
              L'utilisateur peut choisir de configurer son navigateur Web pour
              refuser les cookies ou pour vous alerter lorsque les cookies sont
              envoyés. S'ils le font, notez que certaines parties du Site peut
              ne pas fonctionner correctement. Comment nous utilisons la
              collecte informations, 10mg Pharma peut collecter et utiliser les
              informations personnelles des utilisateurs informations aux fins
              suivantes :
              <ul className=" list-disc ml-6 mt-6">
                <li>Pour améliorer le service client.</li>
                <li>
                  Les informations que vous fournissez nous aident à répondre à
                  votre client les demandes de service et les besoins
                  d’assistance plus efficacement.
                </li>
                <li>Pour améliorer notre site.</li>
                <li>
                  Nous pouvons utiliser les commentaires que vous fournissez
                  pour améliorer nos produits et prestations de service.
                </li>
                <li>Pour envoyer des e-mails périodiques.</li>
                <li>
                  Nous pouvons utiliser l'adresse e-mail pour envoyer des
                  informations sur l'utilisateur et mises à jour relatives à
                  leur commande. Il peut également être utilisé pour répondre à
                  leurs demandes de renseignements, questions et/ou autres
                  demandes. Si l'utilisateur décide de s'inscrire à notre liste
                  de diffusion, il le fera recevoir des e-mails pouvant inclure
                  des actualités de l'entreprise, des mises à jour, des
                  informations liées informations sur le produit ou le service,
                  etc. Si à tout moment l'utilisateur souhaitez vous désabonner
                  de la réception de futurs e-mails, nous inclure des
                  instructions de désabonnement détaillées au bas de chaque
                  e-mail.
                </li>
              </ul>
            </p>
          </section>
          <section ref={section5}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Comment nous protégeons vos informations
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous adoptons une collecte, un stockage et un traitement
              appropriés des données pratiques et mesures de sécurité pour se
              protéger contre les accès, modification, divulgation ou
              destruction de vos données personnelles informations, nom
              d'utilisateur, mot de passe, informations et données sur les
              transactions stockés sur notre Site.
            </p>
          </section>
          <section ref={section6}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Partager vos informations personnelles
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous ne vendons, n'échangeons ni ne louons l'identification
              personnelle des utilisateurs. informations à d’autres. Nous
              pouvons partager des données démographiques agrégées génériques
              informations non liées à des informations d’identification
              personnelle concernant les visiteurs et les utilisateurs avec nos
              partenaires commerciaux, de confiance affiliés et annonceurs aux
              fins décrites ci-dessus. Nous pouvons faire appel à des
              prestataires de services tiers pour nous aider à exploiter notre
              entreprise et le Site ou administrer des activités en notre nom,
              telles que envoi de newsletters ou d'enquêtes. Nous pouvons
              partager vos informations avec ces tiers à ces fins limitées, à
              condition que vous nous avez donné votre autorisation.
            </p>
          </section>
          <section ref={section7}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Sites Web tiers
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Les utilisateurs peuvent trouver de la publicité ou d'autres
              contenus sur notre site qui renvoient aux sites et services de nos
              partenaires, fournisseurs, annonceurs, sponsors, concédants de
              licence et autres tiers. Nous ne contrôlons pas le contenu ou
              liens qui apparaissent sur ces sites et ne sont pas responsable
              des pratiques employées par les sites Web liés à ou depuis notre
              Site. De plus, ces sites ou services, y compris leur contenu et
              leurs liens peuvent être en constante évolution. Ces sites et les
              services peuvent avoir leurs propres politiques de confidentialité
              et clients politiques de services. Navigation et interaction sur
              tout autre site Internet, y compris les sites Web qui ont un lien
              vers notre Site, est soumis à les propres conditions et politiques
              de ce site Web.
            </p>
          </section>
          <section ref={section8}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Modifications de cette politique de confidentialité
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              10mg Pharma a le pouvoir discrétionnaire de mettre à jour cette
              politique de confidentialité à à tout moment. Lorsque nous le
              ferons, nous réviserons la date mise à jour au bas de cette page.
              Nous encourageons les utilisateurs à vérifier fréquemment ceci
              page pour tout changement afin de rester informé de la façon dont
              nous aidons à protéger les informations personnelles que nous
              collectons. Vous reconnaissez et acceptez qu'il est de votre
              responsabilité de consulter cette politique de confidentialité
              périodiquement et prendre connaissance des modifications.
            </p>
          </section>
          <section ref={section9}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Votre acceptation de ces conditions
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              En utilisant ce site, vous signifiez votre acceptation de cette
              politique. Si vous n'acceptez pas cette politique, veuillez ne pas
              utiliser notre site. Ton utilisation continue du site après la
              publication de modifications apportées à ce site politique sera
              considérée comme votre acceptation de ces changements.
            </p>
          </section>
          <section ref={section10}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              nous contacter
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Si vous avez des questions sur cette politique de confidentialité,
              les pratiques de ce site, ou vos relations avec ce site, veuillez
              nous contacter en utilisant l'un de nos canaux fournis ci-dessous
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyFr;
