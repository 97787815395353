import { useState, useRef } from "react";

import toast from "react-hot-toast";

const MapFormFr = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData(event.target);

    try {
      const response = await fetch("https://api.10mg.ai/10mg/send-enquiry", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        formRef.current.reset();
        toast.success("Form submitted successfully!");
      } else {
        toast.error("An error occurred while submitting the form.");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full lg:w-1/2">
      <form
        onSubmit={handleSubmit}
        ref={formRef}
        method="post"
        target="_top"
        className="flex flex-col gap-4 mt-4 font-ibmPlexSans border 
            border-[#EEEEF0] p-5 rounded-xl w-full"
      >
        <h1 className="text-3xl font-pangaia text-berkeleyBlue">
          Avoir des questions? Entrer en contact!
        </h1>
        <div className="flex flex-col gap-4 mt-5">
          <label htmlFor="fullName" className="text-sm  text-berkeleyBlue">
            Nom et prénom
          </label>
          <input
            type="text"
            name="name"
            id="fullName"
            placeholder="Votre nom"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="email"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Adresse e-mail
          </label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="you@company.com"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="pharmName"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Nom de la pharmacie
          </label>
          <input
            type="text"
            name="pharmacy_name"
            id="pharmacyName"
            placeholder="Le nom de votre pharmacie"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="message"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Comment pouvons nous aider?
          </label>
          <textarea
            name="message"
            id="message"
            placeholder="votre message"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          ></textarea>
        </div>
        <button
          type="submit"
          className="inline-flex justify-center rounded-full border border-berkeleyBlue 
                        bg-transparent px-4 py-2 text-sm font-medium text-berkeleyBlue  hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          {isLoading ? "Sending..." : "Envoyer le message"}
        </button>
      </form>
    </div>
  );
};

export default MapFormFr;
